<template>
  <v-app style="background-color: #f2f2f2">
    <v-layout row wrap align-center justify-center>
      <v-flex lg4 md5 sm7 xs7 class="text-center">
        <img
          src="@/assets/logo_onebox-horizontal-png.png"
          width="45%"
          height="45%"
          alt="OneBox"
        />
        <br />
        <br />
        <v-progress-linear
          v-if="fail_ === false"
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
        <br />
        <p v-if="fail_ === false" class="text-center">รอสักครู่</p>
        <p v-else class="text-center">
          <v-icon color="error">error</v-icon>&nbsp;{{ errormessage }}
        </p>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function() {
    return {
      fail_: false,
      errormessage: "",
    };
  },
  created() {},
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataLoginDefault",
    ]),
  },
  methods: {
    reset_login() {
      this.$store
        .dispatch("authorize_expired")
        .then(this.login())
        .catch((error) => {
          console.log(error);
        });
    },
    async login() {
      console.log(
        "this.$route.query.accesstoken",
        this.$route.query.sharetoken
      );
      //เช็คว่ามี accesstoken มั้ย
      if (!this.$route.query.sharetoken) {
        this.fail_ = true;
        this.errormessage = "Accesss Denied !!";
      } else {
        let _accesstoken = this.$route.query.sharetoken;
        let biz_id = this.$route.query.biz_id;

        let payload = {
          shared_token: _accesstoken,
        };

        //localStorage.clear();
        let encodetoken = window.btoa(_accesstoken);
        //localStorage.setItem("token", encodetoken);
        // localStorage.setItem("token", _accesstoken);
        VueCookies.set("token", _accesstoken);
        VueCookies.set("token_auth", true);

        // 1 ยิง API getprofile
        let check_auth = await this.$store.dispatch(
          "authorize_login_business_sharedtoken",
          payload
        );
        console.log("check_auth.status", check_auth.status);
        if (check_auth.status === "Access Granted") {
          // ส่ง event ไปยัง Google Analytics
          this.$gtag.event('login', {
            event_category: "bypass_business",
          });
          
          VueCookies.set("checklogin", true);
          console.log("dataLoginDefault", this.dataLoginDefault);
          console.log("dataBusinessProfile", this.dataBusinessProfile);
          let databusiness = this.dataBusinessProfile.filter(item => item.id === biz_id);
          console.log(databusiness);
          // VueCookies.set("checklogin", true, 60 * 60 * 24);
          if (biz_id) {
            let payload_bu = {
              accesstoken: this.dataAccesstoken,
              biz_id: biz_id,
              branch_no: databusiness[0].branch_no.length > 0 ? databusiness[0].branch_no : "00000",
              taxid: databusiness[0].id_card_num.length > 0 ? databusiness[0].id_card_num : ""
            }
            let check_auth_bu = await this.$store.dispatch(
              "switchaccount_business",
              payload_bu
            );
            console.log("check_auth_bu.status", check_auth_bu.status);
            if (check_auth_bu.status === "Business Access Granted") {
              console.log("dataAccountActive", this.dataAccountActive);
              this.$router.push({ path: "/directory/" + this.dataAccountActive.business_info.my_folder_id});
            } else {
              this.fail_ = true;
              this.errormessage = "ไม่สามารถ login ได้ !!";
            }
          } else {
            this.$router.push({ path: "/mydrive" });
          }
        } else {
          this.fail_ = true;
          this.errormessage = "ไม่สามารถ login ได้ !!";
        }
      }
    },
  },
  mounted() {
    console.log(",kkkkkkkkk", this.$route.query);
    this.reset_login();
  },
};
</script>
  